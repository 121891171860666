<template>
  <left-and-right>
    <div slot="leftDiv" class="left">
      <ul>
        <li class="year">
          <h2 class="yearPoint">&bull;</h2>
          <h1>In 2021</h1>
        </li>

        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.05.27</strong>
          <p>与西安外国语大学经济金融学院签署战略协议，共同完成《陕西省服务贸易企业高质量发展评价系统》的开发与实施。</p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.05.27</strong>
          <p>陕西省商务厅拟于7月中下旬举办陕西省服务贸易大会，按照省厅安排，协会在服贸大会期间举办第一届服务贸易高峰论坛（以下简称“论坛”），该论坛方案于5月27日正式上报陕西省西咸新区发展改革局。</p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.04.15</strong>
          <p>协会在第八届中国（上海）国际技术进出口交易会开幕当日分别与上海巍学信息科技有限公司签署“建设东西部企业服务共享平台”项目；与江苏正本新就业研究院有限公司签署“打造东西部首家企业新技术人才培训基地”项目。</p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.04.15-17</strong>
          <p>完成了上交会的招（组）展工作。根据《陕西省商务厅&lt;关于请西安软件协会和陕西西咸新区服务贸易协会协助组织第八届中国（上海）国际技术进出口交易会参展事宜>的函》的要求，陕西西咸新区服务贸易协会具体负责陕西特装馆方案的招（投）标及搭建布展工作；负责参展企业展会期间路演和签约的组织实施工作；负责陕西会刊、会章的设计和制作工作；负责协调整个参展团组在沪后勤保障工作。</p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.01.31</strong>
          <p>申报中服贸2021年度中国服务贸易协会研究课题，课题名称：基于新基建的贸易便利化与区域间协同开放研究，现已立项。</p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.01.21</strong>
          <p>根据陕西省西咸新区改革创新发展局《关于申报陕西省西咸新区2020年度外经贸发展专项资金服务贸易公共平台专项资金的通知》（陕西咸发改发﹝2021﹞7号）文件要求，协会具备申报服务贸易公共平台专项资金资格，2月5日该项目通过专家评审并立项。</p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2021.01.31</strong>
          <p>申报中服贸2021年度中国服务贸易协会研究课题，课题名称：基于新基建的贸易便利化与区域间协同开放研究，现已立项。</p>
        </li>
        <li class="year">
          <h2 class="yearPoint">&bull;</h2>
          <h1>In 2020</h1>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2020.12.17</strong>
          <p>
            陕西西咸新区服务贸易协会举行揭牌仪式。陕西省商务厅服贸处副处长彭鹏、西安市商务局副局长白杨、西咸新区自贸办专职副主任李朝杰、西咸新区行政审批局行政审批部部长申权利、西咸新区组织部副部长车飞、秦汉新城自贸办专职副主任黄迪、空港新城自贸办专职副主任郭妍菲、园办自贸办专职副主任李晓庆、中国服务贸易协会副秘书长李俊、陕西西咸新区服贸协会会长张震北等领导出席揭牌仪式。
          </p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2020.09.25</strong>
          <p>
            在秦汉大厦召开西咸新区服贸协会第一次会员代表大会。选举产生会长、秘书长、理事单位成员；举手表决通过协会章程、财务管理制度、用印制度，等。
          </p>
        </li>
        <li class="ordinary">
          <h2>&bull;</h2>
          <strong>2020.01</strong>
          <p>
            根据陕西省西咸新区发展改革局、陕西省西咸新区财政局《关于印发&lt;西咸新区行业协会经费补助细则>的通知》（陕西咸发改发〔2021〕30号）要求，协会具备申报该项目资格。2021年3月该通过专家评审并立项。
          </p>
        </li>
      </ul>
    </div>
    <div slot="rightDiv" class="right">
      <div class="photo"></div>
      <img src="../../assets/image/web.png">
    </div>
  </left-and-right>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";

export default {
  name: "deeds",
  components: {
    leftAndRight
  }
}
</script>

<style scoped>
.left ul{
  list-style: none;
  padding-left: 100px;
}
.year{
  width: 200px;
  height: 50px;
}
.year h2{
  color: cornflowerblue!important;
  display: block;
  width: 20px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  margin: 0;
  position: absolute;
  top: -20px;
  left: -10px;
}
.year h1{
  margin: 0;
  color: #ff3300;
  display: block;
  position: absolute;
  font-size: 22px;
  width: 100px;
  height: 50px;
  text-align: center;
  left: -100px;
}
.left ul li{
  width: 96%;
  border-left: 1px dotted #666666;
  position: relative;
  padding:0 2%;
}
.ordinary h2{
  display: block;
  color: #666666;
  width: 10px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  top: -10px;
  left: -5px;
}
.right img {
  width: 100%;
}
.left p{
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}

.photo {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
  background-image: url("../../assets/image/xieshougongyin.png");
  background-size: 150% 100%;
  background-position: 50% 50%;
}

</style>